/* ==========================================================================
  main.js
========================================================================== */

/**
 * Init dropdown-menus
 */
var dropdown_menus = document.querySelectorAll('.js-navigation');
for (var i = 0; i < dropdown_menus.length; i++) {
  component_dropdown_menu({
    desktop_min_width: 980,
    menu: dropdown_menus[i]
  });
}

/**
 * Init mobile menu
 */
component_mobile_menu({
  menu:     document.querySelector('.js-mobile-menu'),
  site:     document.querySelector('.js-page'),
  toggles:  document.querySelectorAll('.js-menu-toggle')
});

/**
 * Init Responsive videos
 */
fitvids();

/**
 * Init polyfill object-fit for lazyloaded
 */
if (typeof objectFitPolyfill === "function") {
  document.addEventListener('lazybeforeunveil', function(e){
    objectFitPolyfill();
    e.target.addEventListener('load', function() {
      objectFitPolyfill();
    });
  });
}

/**
 * Init lightbox
 */
if (document.querySelectorAll('.lightbox').length) {
  try {
    new Tobi({
      // variables from script localization in register-assets.php
      navLabel: [theme_strings.prev, theme_strings.next],
      closeLabel: theme_strings.close,
      loadingIndicatorLabel: theme_strings.loading,
      captionsSelector: 'self',
      captionAttribute: 'data-caption',
      zoom: false,
    });
  } catch (e) {
    console.log(e);
  }
}

/**
 * Sliders with tiny-slider.js
 */

// Home introduction slider
if (document.querySelectorAll('.slider-product--active').length) {

  var homeSlider = tns({
    container             : '.slider-product--active',
    items                 : 1,
    slideBy               : 'page',
    autoplay              : true,
    autoplayButtonOutput  : false,
    controlsContainer     : '.slider-product__controls',
    controls              : false,
    navPosition           : 'bottom',
    autoHeight            : true,
    responsive: {
      1080: {
        controls  : true,
        nav       : false,
        autoHeight: false,
      }
    }
  });

}

// Footer Slider
if ( document.querySelectorAll('.footer-slider__images--active').length ) {

  var footerSlider = tns({
    container             : '.footer-slider__images--active',
    items                 : 1,
    controls              : false,
    nav                   : true,
    navPosition           : 'bottom',
    autoplay              : true,
    autoplayTimeout       : 3000,
    autoplayButtonOutput  : false,
    rewind                : false,
    swipeAngle            : false,
    prevButton            : '#footer-sliderPrev',
    nextButton            : '#footer-sliderNext',
    autoWidth             : true,
    gutter                : 0,
    responsive: {
      720: {
        items                 : 2,
        nav                   : false,
        controls              : true,
        gutter                : 40,
      }
    }
  });

}

/**
 * Vanilla JS Smooth scrolling
 * @source : https://webdesign.tutsplus.com/tutorials/smooth-scrolling-vanilla-javascript--cms-35165
 */
function clickHandler(e) {
  e.preventDefault();
  const href = this.getAttribute("href");
  const offsetTop = document.querySelector(href).offsetTop;

  scroll({
    top: offsetTop,
    behavior: "smooth"
  });
}

const links = document.querySelectorAll(".smooth-scroll");

for (const link of links) {
  link.addEventListener("click", clickHandler);
}
